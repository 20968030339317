import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import FirstPage from '@material-ui/icons/FirstPage';
import IconReplay from '@material-ui/icons/Replay';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RowsPerPageOption } from 'types';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    overflow: 'auto',
    width: '100%',
    '&:last-child': {
      padding: 0,
    },
  },
  toolbar: {
    width: '100%',
    minHeight: 52,
    paddingRight: 2,
  },
  spacer: {
    flex: '1 1 100%',
  },
  caption: {
    flexShrink: 0,
  },
  selectRoot: {
    marginRight: 32,
    marginLeft: 8,
  },
  select: {
    paddingLeft: 8,
    paddingRight: 24,
    textAlign: 'right',
    textAlignLast: 'right',
  },
  selectIcon: {},
  input: {
    color: 'inherit',
    fontSize: 'inherit',
    flexShrink: 0,
  },
  menuItem: {},
  actions: {
    flexShrink: 0,
    marginLeft: 20,
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  progress: {
    color: theme.palette.text.primary,
  },
}));

const defaultRowsPerPageOptions: RowsPerPageOption[] = [
  { value: 10, label: '10 rows' },
  { value: 20, label: '20 rows' },
  { value: 50, label: '50 rows' },
];

const TablePagination = ({
  rowsPerPageOptions = defaultRowsPerPageOptions,
  limit,
  labelRowsPerPage = '',
  handleChangeLimit,
  handleNextPage,
  handlePrevPage,
  hasNextPage,
  hasPreviousPage,
  handleFirstPage,
  page,
  totalCount,
  isLoading,
  handleReload,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.spacer} />
        <div className={classes.loaderWrapper}>
          <IconButton disabled={isLoading} onClick={handleReload}>
            {isLoading ? (
              <CircularProgress
                className={classes.progress}
                thickness={4.6}
                size={theme.spacing(2.4)}
              />
            ) : (
              <IconReplay className={classes.progress} />
            )}
          </IconButton>
        </div>
        {rowsPerPageOptions.length > 1 && (
          <Typography
            color="inherit"
            variant="body2"
            className={classes.caption}
          >
            {labelRowsPerPage}
          </Typography>
        )}
        {rowsPerPageOptions.length > 1 && (
          <Select
            classes={{
              select: classes.select,
              icon: classes.selectIcon,
            }}
            input={
              <InputBase className={clsx(classes.input, classes.selectRoot)} />
            }
            value={limit}
            onChange={({ target }) => handleChangeLimit(target.value)}
            inputProps={{ 'aria-label': labelRowsPerPage }}
          >
            {rowsPerPageOptions.map((rowsPerPageOption) => (
              <MenuItem
                className={classes.menuItem}
                key={String(
                  rowsPerPageOption.value
                    ? rowsPerPageOption.value
                    : rowsPerPageOption,
                )}
                value={String(
                  rowsPerPageOption.value
                    ? rowsPerPageOption.value
                    : rowsPerPageOption,
                )}
              >
                {rowsPerPageOption.label
                  ? rowsPerPageOption.label
                  : rowsPerPageOption}
              </MenuItem>
            ))}
          </Select>
        )}
        <Typography color="inherit" variant="body2" className={classes.caption}>
          {`${page * limit - limit}-${
            page * limit > totalCount ? totalCount : page * limit
          } of ${totalCount}`}
        </Typography>
        <div className={classes.actions}>
          <IconButton
            onClick={handleFirstPage}
            disabled={!hasPreviousPage}
            color="inherit"
          >
            <FirstPage />
          </IconButton>
          <IconButton
            onClick={handlePrevPage}
            disabled={!hasPreviousPage}
            color="inherit"
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={handleNextPage}
            disabled={!hasNextPage}
            color="inherit"
          >
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </Toolbar>
    </div>
  );
};

export default TablePagination;
