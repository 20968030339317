import { ElementType, ReactNode } from 'react';
import {
  Size as TableSizeProp,
  TableProps as MUITableProps,
} from '@material-ui/core/Table';
import { PaperProps as MUIPaperProps } from '@material-ui/core/Paper';
import { DocumentNode, QueryHookOptions, ApolloError } from '@apollo/client';
import type { TableFilterAutocompleteColumnProps } from './TableFilterAutocomplete';

export type FilterElements = {
  value: string | number | null;
  text: string | number;
};

export type FilterValue =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | null;

export type Filter = {
  column: string;
  value: FilterValue;
};

export type Action =
  | ReactNode
  | {
      name: 'edit';
      onClick: (row: any) => null;
    };

export type Column = {
  filterElements?: FilterElements[];
  filterQuery?: string;
  render?: (props: any) => any;
  width?: number | string;
  formatDateValue?: string;
  formatDateFilter?: string;
  disableFilter?: boolean;
  disableFilterIcons?: boolean;
  getIsCheckedAll?: (nodes: any[] | null) => boolean;
  getIsChecked?: (row: any) => boolean;
  onClickAll?: (checked: boolean, nodes: any[] | null) => void;
  makeFilterQuery?: (value: any, column: string) => Record<any, any>;
  getColumnClasses?: (row: any, column: any) => string[];
  defaultValue?: string | number;
} & (
  | {
      label?: string;
      actions: Action[];
      type: 'actions';
      name?: string;
    }
  | {
      label: string;
      name: string;
      type?:
        | 'integer'
        | 'decimal'
        | 'boolean'
        | 'multiSelect'
        | 'select'
        | 'date';
    }
  | {
      name?: string;
      label?: string;
      type: 'checkbox';
      onClick: (row: any, checked: boolean) => void;
      onClickAll: (checked: boolean, nodes: any[] | null) => void;
      getIsCheckedAll: (nodes: any[] | null) => boolean;
      getIsChecked: (row: any) => boolean;
    }
  | TableFilterAutocompleteColumnProps
);

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

export type Data = {
  [key: string]: { nodes: any[]; pageInfo: PageInfo; totalCount: number };
};

export enum SortTypes {
  ASC = 'asc',
  DESC = 'desc',
}

export type Sort = {
  column: string;
  type: SortTypes;
};

export type RowsPerPageOption = {
  value: number;
  label: string;
};

export type GraphQLTableStateReturnProps = {
  pageInfo: PageInfo;
  error?: ApolloError;
  nodes: any[] | null;
  isLoading: boolean;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  limit: number;
  handleChangeLimit: (value: number) => void;
  handleFirstPage: () => void;
  page: number;
  totalCount: number;
  handleToggleSort: (column?: string) => void;
  sortBy: Sort[];
  filterBy: Filter[];
  handleChangeFilter: (column: string, value: FilterValue) => void;
  handleReload: () => void;
};

export type Props = {
  columns: Column[];
  title?: string;
  onRowClick?: (row: any) => void;
  primaryColumn?: string;
  getKey?: (row: any) => string;
  renderToolbar?: () => ReactNode;
  renderActions?: () => ReactNode;
  size?: TableSizeProp;
  rowComponent?: ElementType;
  getIsSelectedRow?: (row: any) => boolean;
  getRowClasses?: (row: any) => string[];
  getCellClasses?: () => string[];
  getLoaderClasses?: () => string[];
  disableHeaderLoader?: boolean;
  staticRowsComponent?: ReactNode;
  displayEmptyRows?: boolean;
  defaultOrder?: string;
  defaultSort?: 'desc' | 'asc';
  rowsPerPage?: number;
  query: DocumentNode;
  condition?: Record<string, unknown> | null;
  defaultColumn?: string;
  queryOptions?: QueryHookOptions;
  tableProps?: MUITableProps;
  paperProps?: MUIPaperProps;
  renderEmptyComponent?: () => ReactNode;
  renderLoadingComponent?: () => ReactNode;
  tableSortLabelComponent?: ElementType;
  rowsPerPageOptions?: RowsPerPageOption[];
};

export type TableProps = Props & GraphQLTableStateReturnProps;
