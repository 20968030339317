import React, { useEffect, useState } from 'react';
import moment from 'moment';
import dotProp from 'dot-prop';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MUITableBody from '@material-ui/core/TableBody';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import { Column, TableProps } from './types';
import TableActions from './TableActions';

const useStyles = makeStyles(() =>
  createStyles({
    pointer: {
      cursor: 'pointer',
    },
  }),
);

const getRenderValue = (row: any, column: Column) => {
  const { name, type, filterElements, render } = column;
  if (name) {
    if (render) {
      return render({ ...column, value: dotProp.get(row, name), row });
    }
    if (!type || type === 'integer' || type === 'autocomplete') {
      return dotProp.get(row, name);
    }
    if (type === 'decimal') {
      return Number(dotProp.get(row, name)).toFixed(2);
    }
    if (type === 'multiSelect' || type === 'select') {
      if (!filterElements) {
        return 'not found';
      }
      const item = filterElements.find(
        ({ value }) => value === dotProp.get(row, name),
      );
      return item?.text ?? 'not found';
    }
    if (type === 'boolean') {
      return dotProp.get(row, name) ? 'Yes' : 'No';
    }
    if (type === 'date') {
      return moment(dotProp.get(row, name)).format(
        column.formatDateValue || 'MM/DD/YYYY',
      );
    }
  }
  return '';
};

const getRowKey = (row, primaryColumn, getKey) => {
  if (getKey) {
    return getKey(row);
  }
  return dotProp.get(row, primaryColumn);
};

const TableBody = ({
  columns,
  primaryColumn,
  getKey,
  onRowClick,
  nodes,
  limit,
  rowComponent,
  getIsSelectedRow,
  getRowClasses,
  staticRowsComponent,
  displayEmptyRows,
  renderEmptyComponent,
  isLoading,
  renderLoadingComponent,
}: TableProps) => {
  const [items, setItems] = useState(nodes || []);

  useEffect(() => {
    if (nodes) {
      setItems(nodes);
    }
  }, [nodes]);

  const classes = useStyles();
  const emptyRows = limit - items.length;
  const Row = rowComponent || TableRow;
  return (
    <MUITableBody>
      {!isLoading &&
        items.length === 0 &&
        renderEmptyComponent &&
        renderEmptyComponent()}
      {isLoading && renderLoadingComponent && renderLoadingComponent()}
      {items.map((row: any) => (
        <Row
          key={getRowKey(row, primaryColumn, getKey)}
          hover
          onClick={() => onRowClick && onRowClick(row)}
          className={clsx(
            [onRowClick && classes.pointer].concat(
              getRowClasses ? getRowClasses(row) : [],
            ),
          )}
          selected={getIsSelectedRow ? getIsSelectedRow(row) : false}
        >
          {columns.map((column) => {
            if (column.type === 'actions') {
              return (
                <TableActions
                  key="actions"
                  actions={column.actions}
                  row={row}
                  className={clsx(
                    column.getColumnClasses
                      ? column.getColumnClasses(row, column)
                      : [],
                  )}
                />
              );
            }
            if (column.type === 'checkbox') {
              return (
                <TableCell
                  key={`${row.id}-checkbox`}
                  padding="default"
                  scope="row"
                  className={clsx(
                    column.getColumnClasses
                      ? column.getColumnClasses(row, column)
                      : [],
                  )}
                >
                  <Checkbox
                    color="default"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(event) => {
                      if (column.onClick) {
                        column.onClick(row, event.target.checked);
                      }
                    }}
                    checked={column.getIsChecked && column.getIsChecked(row)}
                  />
                </TableCell>
              );
            }
            return (
              <TableCell
                key={`${row.id}-${column.label}`}
                align={
                  column.type === 'integer' || column.type === 'decimal'
                    ? 'right'
                    : 'left'
                }
                scope="row"
                className={clsx(
                  column.getColumnClasses
                    ? column.getColumnClasses(row, column)
                    : [],
                )}
              >
                {getRenderValue(row, column)}
              </TableCell>
            );
          })}
        </Row>
      ))}
      {displayEmptyRows && emptyRows > 0 && (
        <TableRow style={{ height: 81 * emptyRows }}>
          <TableCell colSpan={100} />
        </TableRow>
      )}
      {staticRowsComponent}
    </MUITableBody>
  );
};

export default TableBody;
