import snakeCase from 'lodash.snakecase';
import { Data, Sort, SortTypes, Filter, Column, FilterValue } from './types';

export const getData = (data: Data) => {
  if (data) {
    const keys = Object.keys(data);
    if (keys.length) {
      return data[keys[0]];
    }
  }
  return {
    nodes: null,
    totalCount: 0,
    pageInfo: {
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: '',
    },
  };
};

export const toggleSortColumn = (sortBy: Sort[], toggleColumn: string) => {
  const toggle = sortBy.find(({ column }) => column === toggleColumn);
  if (!toggle) {
    return [...sortBy, { column: toggleColumn, type: SortTypes.ASC }];
  }
  if (toggle.type === SortTypes.ASC) {
    return sortBy.map((item) => {
      if (item.column === toggleColumn) {
        return { ...item, type: SortTypes.DESC };
      }
      return item;
    });
  }
  return sortBy.filter(({ column }) => column !== toggleColumn);
};

export const getSortDirection = (sortBy: Sort[], columnName?: string) => {
  const sort = sortBy.find(({ column }) => column === columnName);
  if (!sort || !columnName) {
    return false;
  }
  return sort.type;
};

export const makeOrderBy = (sortBy: Sort[]) =>
  sortBy.map(
    ({ column, type }) =>
      `${snakeCase(column).toUpperCase()}_${type.toUpperCase()}`,
  );

export const setFilterColumn = (
  filterBy: Filter[],
  setColumn: string,
  value: FilterValue,
) => {
  const set = filterBy.find(({ column }) => column === setColumn);
  if (
    value === '' ||
    value === null ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return filterBy.filter(({ column }) => column !== setColumn);
  }
  if (!set) {
    return [...filterBy, { column: setColumn, value }];
  }
  return filterBy.map((item) => {
    if (item.column === setColumn) {
      return { ...item, value };
    }
    return item;
  });
};

const makeFilterItem = (
  { filterQuery, type, makeFilterQuery }: Column,
  column: string,
  value: any,
) => {
  if (makeFilterQuery) {
    return makeFilterQuery(value, column);
  }
  if (filterQuery) {
    return { [column]: { [filterQuery]: value } };
  }
  if (!type) {
    return { [column]: { includesInsensitive: value } };
  }
  if (type === 'decimal') {
    const newValue = String(value).split('.')[0];
    return {
      and: [
        { [column]: { greaterThanOrEqualTo: Math.round(Number(newValue)) } },
        { [column]: { lessThanOrEqualTo: Math.round(Number(newValue)) + 1 } },
      ],
    };
  }
  if (type === 'integer') {
    return { [column]: { equalTo: Number(value) } };
  }
  if (type === 'boolean' || type === 'select') {
    return { [column]: { equalTo: value } };
  }
  if (type === 'multiSelect') {
    return { [column]: { in: value } };
  }
  if (type === 'date') {
    return {
      and: [
        { [column]: { greaterThanOrEqualTo: value[0] } },
        { [column]: { lessThanOrEqualTo: value[1] } },
      ],
    };
  }
  return {};
};

export const makeFilter = (
  filter: Filter[],
  columns: Column[],
  _primaryColumn: string,
  condition: Record<string, unknown> | null,
) => {
  const defaultFilter = condition || {};
  const newFilter = filter.reduce((acc, { column, value }) => {
    const columnConfig = columns.find(({ name }) => name === column);
    if (!columnConfig) {
      throw Error(
        `"Table" component: column "${column}" not found in columns props`,
      );
    }
    const item = makeFilterItem(columnConfig, column, value);
    return {
      ...acc,
      ...item,
    };
  }, defaultFilter);
  return Object.keys(newFilter).length === 0 ? undefined : newFilter;
};

export const makeFilterDefault = (
  columns: Column[],
  _primaryColumn: string,
  // condition: Record<string, unknown> | null,
) =>
  columns.reduce<Filter[]>((acc, { name, defaultValue }) => {
    if (defaultValue && name) {
      return [...acc, { column: name, value: defaultValue }];
    }
    return [...acc];
  }, [] as Filter[]);
