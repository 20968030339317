import { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  getData,
  toggleSortColumn,
  makeOrderBy,
  makeFilter,
  setFilterColumn,
  makeFilterDefault,
} from './utils';
import { Sort, Filter, Props } from './types';

type State = {
  after: string | null;
  before: string | null;
  first: number | null;
  last: number | null;
  limit: number;
  page: number;
  sortBy: Sort[];
  filterBy: Filter[];
};

export const useGraphQLTableState = ({
  query,
  columns,
  rowsPerPage = 10 || 20 || 50,
  defaultColumn = 'id',
  condition = null,
  queryOptions = {},
}: Props) => {
  const [state, setState] = useState<State>({
    sortBy: [],
    filterBy: makeFilterDefault(columns, defaultColumn),
    after: null,
    before: null,
    first: rowsPerPage,
    last: null,
    limit: rowsPerPage,
    page: 1,
  });

  const { after, before, first, last, limit, page, sortBy, filterBy } = state;

  const { error, data, refetch, networkStatus } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    pollInterval: 30 * 1000,
    ...queryOptions,
    variables: {
      after,
      before,
      first,
      last,
      filter: makeFilter(filterBy, columns, defaultColumn, condition),
      orderBy: makeOrderBy(sortBy),
    },
  });
  const isLoading =
    networkStatus === 1 ||
    networkStatus === 2 ||
    networkStatus === 3 ||
    networkStatus === 4;
  const { nodes, pageInfo, totalCount } = getData(data);
  const { hasNextPage, hasPreviousPage } = pageInfo;

  const handleNextPage = useCallback(() => {
    setState({
      ...state,
      before: null,
      last: null,
      first: state.limit,
      after: pageInfo.endCursor,
      page: state.page + 1,
    });
  }, [state, pageInfo]);

  const handlePrevPage = useCallback(() => {
    setState({
      ...state,
      after: null,
      first: null,
      last: state.limit,
      before: pageInfo.startCursor,
      page: state.page - 1,
    });
  }, [state, pageInfo]);

  const handleFirstPage = useCallback(() => {
    setState({
      ...state,
      after: null,
      first: state.limit,
      last: null,
      before: null,
      page: 1,
    });
  }, [state, pageInfo]);

  const handleChangeLimit = useCallback(
    (newLimit) => {
      setState({
        ...state,
        after: null,
        first: Number(newLimit),
        last: null,
        before: null,
        page: 1,
        limit: Number(newLimit),
      });
    },
    [state],
  );

  const handleToggleSort = useCallback(
    (column) => {
      setState({
        ...state,
        sortBy: toggleSortColumn(state.sortBy, column),
        after: null,
        first: state.limit,
        last: null,
        before: null,
        page: 1,
      });
    },
    [state],
  );

  const handleChangeFilter = useCallback(
    (column, value) => {
      setState({
        ...state,
        after: null,
        first: state.limit,
        last: null,
        before: null,
        page: 1,
        filterBy: setFilterColumn(state.filterBy, column, value),
      });
    },
    [state],
  );

  const handleReload = useCallback(() => {
    refetch();
  }, [state]);

  return {
    error,
    nodes,
    pageInfo,
    isLoading,
    handleNextPage,
    handlePrevPage,
    hasNextPage,
    hasPreviousPage,
    handleChangeLimit,
    limit,
    handleFirstPage,
    totalCount,
    page,
    handleToggleSort,
    sortBy,
    filterBy,
    handleChangeFilter,
    handleReload,
  };
};
