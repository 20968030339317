import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MiuTable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { TableProps } from './types';
import TableHead from './TableHead';
import TablePagination from './TablePagination';
import TableToolbar from './TableToolbar';
import TableBody from './TableBody';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    marginBottom: theme.spacing(2),
  },
  table: {},
}));

const renderDefaultToolbar = (title, renderActions) => {
  return <TableToolbar title={title} renderActions={renderActions} />;
};

const Table = (props: TableProps) => {
  const classes = useStyles();
  const { renderToolbar, title, renderActions, tableProps, paperProps } = props;
  const toolbar = renderToolbar
    ? renderToolbar()
    : renderDefaultToolbar(title, renderActions);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} {...paperProps}>
        {toolbar}
        <TableContainer>
          <MiuTable
            stickyHeader
            className={classes.table}
            aria-label="custom pagination table"
            {...tableProps}
          >
            <TableHead {...props} />
            <TableBody {...props} />
          </MiuTable>
        </TableContainer>
        <TablePagination {...props} />
      </Paper>
    </div>
  );
};

export default Table;
