import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Action } from './types';

export type Props = {
  actions: Action[];
  row: any;
};

const renderAction = (action: Action, row: any) => {
  if (action && typeof action === 'object' && 'name' in action) {
    if (action.name === 'edit') {
      return (
        <IconButton
          key={action.name}
          aria-label="edit"
          onClick={(e) => {
            e.stopPropagation();
            action.onClick(row);
          }}
        >
          <EditIcon />
        </IconButton>
      );
    }
    if (action.name === 'delete') {
      return (
        <IconButton
          key={action.name}
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation();
            action.onClick(row);
          }}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  } else if (typeof action === 'function') {
    return action(row);
  }
  return action;
};

const TableActions = ({ actions, row, className }) => {
  return (
    <TableCell component="th" scope="row" align="right" className={className}>
      {actions.map((action) => renderAction(action, row))}
    </TableCell>
  );
};

export default TableActions;
