import React, { useState, useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilterIcon from '@material-ui/icons/FilterList';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { DateRangePicker, DateRange } from '@material-ui/pickers';
import debounce from 'debounce';
import TableFilterAutocomplete from './TableFilterAutocomplete';

const useStyles = makeStyles(() =>
  createStyles({
    rowHeader: {
      height: '55px',
    },
  }),
);

const TextFieldFilter = ({
  name,
  type,
  disableFilterIcons,
  handleChangeFilter,
}) => {
  const [value, setValue] = useState('');

  const triggerChange = useCallback(
    debounce((newValue: string) => {
      if (name) {
        handleChangeFilter(name, newValue);
      }
    }, 400),
    [name, handleChangeFilter],
  );

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
    triggerChange(target.value);
  };

  return (
    <TextField
      style={type === 'integer' || type === 'decimal' ? { float: 'right' } : {}}
      type={type === 'integer' || type === 'decimal' ? 'number' : 'search'}
      value={value}
      placeholder=""
      onChange={handleChange}
      margin="none"
      InputProps={{
        startAdornment: disableFilterIcons ? null : (
          <InputAdornment position="start">
            <Tooltip title="Filter">
              <FilterIcon fontSize="small" color="action" />
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

const CheckboxFilter = ({ name, handleChangeFilter }) => {
  const [countClick, setCountClick] = useState(0);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newCountClick = countClick + 1;
    if (newCountClick === 3) {
      handleChangeFilter(name, null);
      setCountClick(0);
    } else {
      setCountClick(newCountClick);
      handleChangeFilter(name, target.checked);
    }
  };

  return (
    <Checkbox
      indeterminate={countClick === 0}
      checked={countClick === 1}
      onChange={handleChange}
    />
  );
};

const MultiSelectFilter = ({ filterElements, name, handleChangeFilter }) => {
  if (!filterElements) {
    throw Error(
      `"Table" component: column "${name}" not set prop filterElements`,
    );
  }
  const [values, setValues] = useState<string[]>([]);

  const handleChange = ({ target }: React.ChangeEvent<{ value: unknown }>) => {
    setValues(target.value as string[]);
    handleChangeFilter(name, target.value as string[]);
  };

  return (
    <FormControl margin="none" style={{ width: '100%' }}>
      <Select
        multiple
        value={values}
        onChange={handleChange}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={(selecteds: any) => {
          if (selecteds.length === 0) {
            return <em>All</em>;
          }
          return selecteds
            .map((selected) => {
              const find = filterElements.find(
                ({ value }) => value === selected,
              );
              if (!find) {
                return 'Not set';
              }
              return find.text;
            })
            .join(', ');
        }}
        // MenuProps={MenuProps}
        style={{ marginTop: 0 }}
      >
        {filterElements.map(({ value, text }) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={values.includes(value)} />
            <ListItemText primary={text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SelectFilter = ({
  filterElements,
  name,
  handleChangeFilter,
  defaultValue,
}) => {
  if (!filterElements) {
    throw Error(
      `"Table" component: column "${name}" not set prop filterElements`,
    );
  }
  const [value, setValue] = useState(defaultValue || filterElements[0].value);

  const handleChange = ({ target }: React.ChangeEvent<{ value: unknown }>) => {
    setValue(target.value as string);
    handleChangeFilter(name, target.value as string);
  };

  return (
    <FormControl margin="none" style={{ width: '100%' }}>
      <Select
        multiple={false}
        value={value}
        onChange={handleChange}
        input={<Input id="select-checkbox" />}
        style={{ marginTop: 0 }}
      >
        {filterElements.map(({ value: v, text }) => (
          <MenuItem key={v} value={v}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const DateFilter = ({
  handleChangeFilter,
  name,
  formatDateValue = 'MM/DD/YYYY',
  formatDateFilter,
}) => {
  const [selectedDate, handleDateChange] = React.useState<DateRange>([
    null,
    null,
  ]);
  const onChange = (date) => {
    handleDateChange(date);
    const [from, to] = date;
    if (from && to) {
      handleChangeFilter(name, date);
    }
  };
  const onClear = () => {
    handleDateChange([null, null]);
    handleChangeFilter(name, null);
  };
  const dateFormat = formatDateFilter || formatDateValue;
  return (
    <DateRangePicker
      startText="Check-in"
      endText="Check-out"
      value={selectedDate}
      onChange={onChange}
      inputFormat={dateFormat}
      renderInput={(startProps, endProps) => {
        const value =
          startProps?.inputProps?.value && endProps?.inputProps?.value
            ? `${startProps.inputProps.value} - ${endProps.inputProps.value}`
            : '';
        return (
          <>
            <TextField
              margin="none"
              ref={startProps.ref}
              // @ts-ignore
              InputProps={{
                ...startProps.inputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Filter">
                      <DateRangeIcon fontSize="small" color="action" />
                    </Tooltip>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onClear}>
                      <ClearIcon fontSize="small" color="action" />
                    </IconButton>
                  </InputAdornment>
                ),
                value,
              }}
            />
          </>
        );
      }}
    />
  );
};

const renderFilterCell = (column, handleChangeFilter) => {
  if (column.disableFilter) {
    return null;
  }
  if (
    column.type === undefined ||
    column.type === 'integer' ||
    column.type === 'decimal'
  ) {
    return (
      <TextFieldFilter {...column} handleChangeFilter={handleChangeFilter} />
    );
  }

  if (column.type === 'boolean') {
    return (
      <CheckboxFilter {...column} handleChangeFilter={handleChangeFilter} />
    );
  }

  if (column.type === 'select') {
    return <SelectFilter {...column} handleChangeFilter={handleChangeFilter} />;
  }

  if (column.type === 'multiSelect') {
    return (
      <MultiSelectFilter {...column} handleChangeFilter={handleChangeFilter} />
    );
  }

  if (column.type === 'date') {
    return <DateFilter {...column} handleChangeFilter={handleChangeFilter} />;
  }
  if (column.type === 'autocomplete') {
    return (
      <TableFilterAutocomplete
        {...column}
        handleChangeFilter={handleChangeFilter}
      />
    );
  }
  return null;
};

const TableFilter = ({ columns, handleChangeFilter }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.rowHeader}>
      {columns.map((column, i) => (
        <TableCell key={`${column.label}-${i}`} align="left" padding="default">
          {renderFilterCell(column, handleChangeFilter)}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableFilter;
