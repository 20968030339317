import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useToolbarStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
  }),
);

const TableToolbar = ({ title, renderActions }) => {
  const classes = useToolbarStyles();
  const actions = renderActions ? renderActions() : null;
  if (!title && !actions) {
    return null;
  }
  return (
    <Toolbar className={clsx(classes.root)}>
      {title ? (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      ) : null}
      {actions}
    </Toolbar>
  );
};

export default TableToolbar;
