import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  lighten,
  fade,
  makeStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import { common } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import TableFilter from './TableFilters';
import { getSortDirection } from './utils';
import { Column, TableProps } from './types';

const WhiteCheckbox = withStyles({
  root: {
    color: common.white,
    '&$checked': {
      color: common.white,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderWrapper: {
      paddingTop: 1,
      backgroundColor: theme.palette.common.white,
    },
    cell: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: 0,
      paddingTop: '5px',
      paddingBottom: '0px',
      top: '30px',
    },
    cellWithoutLoader: {
      paddingBottom: '5px',
    },
    cellWithoutFilter: {
      top: '0px',
    },
    cellLoader: {
      padding: 0,
      border: 0,
      top: '80px',
    },
    cellLoaderWithoutFilter: {
      top: '50px',
    },
  }),
);

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      flexDirection: 'inherit',
      alignItems: 'center',
      '&:focus': {
        color: lighten(fade(theme.palette.text.secondary, 1), 0.88),
      },
      '&:hover': {
        color: lighten(theme.palette.text.secondary, 0.88),
        '&& $icon': {
          opacity: 0.5,
          color: lighten(theme.palette.text.secondary, 0.88),
        },
      },
      '&$active': {
        color: lighten(fade(theme.palette.text.primary, 1), 0.88),
        // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
        '&& $icon': {
          opacity: 1,
          color: lighten(fade(theme.palette.text.secondary, 1), 0.88),
        },
      },
    },
    // don't remove empty active and icon because bug
    active: {},
    icon: {},
  }),
)(TableSortLabel);

const getIsAllFiltersDisabled = (columns: Column[]) =>
  columns.reduce((acc, v) => {
    if (v.type !== 'actions' && acc && !v.disableFilter) {
      return false;
    }
    return acc;
  }, true);

const TableHead = ({
  columns,
  sortBy,
  handleToggleSort,
  handleChangeFilter,
  isLoading,
  nodes,
  getCellClasses,
  getLoaderClasses,
  disableHeaderLoader,
  tableSortLabelComponent,
}: TableProps) => {
  const classes = useStyles();
  const isAllFiltersDisabled = getIsAllFiltersDisabled(columns);
  const cellClasses = clsx([
    classes.cell,
    isAllFiltersDisabled && classes.cellWithoutFilter,
    getCellClasses && getCellClasses(),
    disableHeaderLoader && classes.cellWithoutLoader,
  ]);
  const loaderClasses = clsx([
    classes.cell,
    classes.cellLoader,
    isAllFiltersDisabled && classes.cellLoaderWithoutFilter,
    getLoaderClasses && getLoaderClasses(),
  ]);

  const RTableSortLabel = tableSortLabelComponent || StyledTableSortLabel;

  return (
    <MuiTableHead>
      {!isAllFiltersDisabled && (
        <TableFilter
          columns={columns}
          handleChangeFilter={handleChangeFilter}
        />
      )}
      <TableRow>
        {columns.map(
          ({
            label,
            name,
            type,
            width,
            getIsCheckedAll,
            onClickAll,
          }: Column) => {
            if (type === 'actions') {
              return (
                <TableCell
                  size="medium"
                  key="actions"
                  padding="default"
                  align="right"
                  className={cellClasses}
                  style={width ? { width } : {}}
                >
                  Actions
                </TableCell>
              );
            }
            if (type === 'checkbox') {
              return (
                <TableCell
                  size="medium"
                  key="checkbox"
                  padding="default"
                  className={cellClasses}
                  style={width ? { width } : {}}
                >
                  <WhiteCheckbox
                    onChange={(event) => {
                      if (onClickAll) {
                        onClickAll(event.target.checked, nodes);
                      }
                    }}
                    checked={getIsCheckedAll && getIsCheckedAll(nodes)}
                  />
                </TableCell>
              );
            }
            // @ts-ignore
            return (
              <TableCell
                key={label}
                size="medium"
                align={
                  type === 'integer' || type === 'decimal' ? 'right' : 'left'
                }
                padding="default"
                sortDirection={getSortDirection(sortBy, name)}
                className={cellClasses}
                style={width ? { width } : {}}
              >
                <RTableSortLabel
                  active={!!getSortDirection(sortBy, name)}
                  direction={getSortDirection(sortBy, name) || 'asc'}
                  onClick={() => handleToggleSort(name)}
                >
                  {label}
                </RTableSortLabel>
              </TableCell>
            );
          },
        )}
      </TableRow>
      {!disableHeaderLoader && (
        <TableRow>
          <TableCell className={loaderClasses} colSpan={10000}>
            <Fade in={isLoading}>
              <div className={classes.loaderWrapper}>
                <LinearProgress />
              </div>
            </Fade>
          </TableCell>
        </TableRow>
      )}
    </MuiTableHead>
  );
};

export default TableHead;
