import React, { useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'debounce';

export type TableFilterAutocompleteColumnProps = {
  type: 'autocomplete';
  name: string;
  label?: string;
  useQuery: any;
  queryProps?: Record<any, any>;
  getOptions: (queryResult: any) => any;
  getOptionLabel?: (item: any) => string;
  mapValueToFilter?: (value: string) => any;
  optionName?: string;
  limit?: number;
} & (
  | {
      getOptionLabel: (item: any) => string;
      mapValueToFilter: (value: string) => any;
    }
  | {
      optionName: string;
    }
);

export type TableFilterAutocompleteProps = {
  handleChangeFilter: (name: string, value: null | string) => void;
} & TableFilterAutocompleteColumnProps;

const verifyOptionName = (optionName) => {
  if (!optionName) {
    throw new Error(
      '"optionName" is required if "getOptionLabel", "mapValueToFilter" not defined',
    );
  }
  return optionName;
};

export default function TableFilterAutocomplete({
  useQuery,
  getOptions,
  queryProps = {},
  name,
  optionName,
  limit = 10,
  getOptionLabel = (item) => item[verifyOptionName(optionName)],
  mapValueToFilter = (value) => ({
    [verifyOptionName(optionName)]: { includesInsensitive: value },
  }),
  handleChangeFilter,
}: TableFilterAutocompleteProps) {
  const [value, setValue] = useState<null | any>(null);
  const [inputValue, setInputValue] = useState('');
  const [debouncing, setDebouncing] = useState(false);

  const filter = {
    ...(queryProps?.variables?.filter ?? {}),
    ...mapValueToFilter(inputValue),
  };
  const result = useQuery({
    ...queryProps,
    variables: {
      ...(queryProps?.variables ?? {}),
      first: queryProps?.variables?.first ?? limit,
      filter: Object.keys(filter).length === 0 ? undefined : filter,
    },
    skip: debouncing,
  });

  const options = getOptions(result);

  const handleChange = useCallback(
    (_, newValue) => {
      if (newValue) {
        handleChangeFilter(name, getOptionLabel(newValue));
        setValue(newValue);
      } else {
        handleChangeFilter(name, null);
        setValue(null);
      }
    },
    [name, handleChangeFilter],
  );

  const handleSetDebounce = useCallback(
    debounce(() => {
      setDebouncing(false);
    }, 400),
    [],
  );

  const handleChangeInput = useCallback((_, newInputValue) => {
    if (!debouncing) {
      setDebouncing(true);
    }
    setInputValue(newInputValue);
    handleSetDebounce();
  }, []);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, optionValue) =>
        option[name] === optionValue[name]
      }
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleChangeInput}
      loading={debouncing || result.loading}
      renderInput={(params) => <TextField {...params} margin="none" />}
    />
  );
}
