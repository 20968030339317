import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useGraphQLTableState } from './graphQLTableState';
import Table from './Table';
import { Props } from './types';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const TableGraphQL = ({
  columns,
  title,
  onRowClick = () => {},
  primaryColumn = 'id',
  getKey,
  renderToolbar,
  renderActions,
  size,
  rowComponent,
  getIsSelectedRow,
  getRowClasses,
  getCellClasses,
  staticRowsComponent,
  displayEmptyRows = true,
  rowsPerPage = 10 || 20 || 50,
  defaultColumn = 'id',
  condition = null,
  queryOptions = {},
  defaultOrder,
  defaultSort,
  query,
  tableProps = {},
  paperProps = {},
  renderEmptyComponent = () => null,
  disableHeaderLoader,
  renderLoadingComponent = () => null,
  tableSortLabelComponent,
  rowsPerPageOptions,
}: Props) => {
  const classes = useStyles();
  const props = {
    columns,
    title,
    onRowClick,
    primaryColumn,
    getKey,
    renderToolbar,
    renderActions,
    size,
    rowComponent,
    getIsSelectedRow,
    getRowClasses,
    getCellClasses,
    staticRowsComponent,
    displayEmptyRows,
    rowsPerPage,
    defaultColumn,
    condition,
    queryOptions,
    defaultOrder,
    defaultSort,
    query,
    tableProps,
    paperProps,
    renderEmptyComponent,
    disableHeaderLoader,
    renderLoadingComponent,
    tableSortLabelComponent,
    rowsPerPageOptions,
  };
  const {
    error,
    nodes,
    pageInfo,
    handleNextPage,
    hasNextPage,
    hasPreviousPage,
    handlePrevPage,
    handleChangeLimit,
    limit,
    handleFirstPage,
    totalCount,
    page,
    handleToggleSort,
    sortBy,
    filterBy,
    handleChangeFilter,
    handleReload,
    isLoading,
  } = useGraphQLTableState(props);
  return (
    <div className={classes.root}>
      <Table
        {...props}
        isLoading={isLoading}
        error={error}
        nodes={nodes}
        pageInfo={pageInfo}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        handleChangeLimit={handleChangeLimit}
        limit={limit}
        handleFirstPage={handleFirstPage}
        totalCount={totalCount}
        page={page}
        handleToggleSort={handleToggleSort}
        sortBy={sortBy}
        filterBy={filterBy}
        handleChangeFilter={handleChangeFilter}
        handleReload={handleReload}
      />
    </div>
  );
};

export default TableGraphQL;
